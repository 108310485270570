import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import PacificMap from './PacificMap'

function SelectedClients(props, ref) {

  const { forestImage } = useStaticQuery(graphql`
    query AboutClients {
      forestImage: file(relativePath: { eq: "about/our-clients-1.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 578, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section className='about__selected-clients scroll-section' ref={ref}>
      <div
        className='anchor'
        id='clients'
        aria-label='clients'>anchor</div>
      <h2 className='ics__h1'>Our clients</h2>
      <p className='ics__h2'>We have worked with numerous government departments and agencies spanning accross the Asia-Pacific region. Explore some of our clients below and see how we helped them.</p>
      <div className='about__client'>
        <h3 className='ics__h2'>
          Singapore National Parks Board (NParks)
        </h3>
        <p className='ics__p'><a href="https://www.nparks.gov.sg/" target="_blank" rel="noreferrer noopener"><strong>NParks</strong></a> is responsible for managing the entirety of Singapore’s Government Land and Streetscapes – totalling more than 9,500 hectares and inclusive of each manicured tree in the city. Since 2010, The ICS Lands-Tracker™ system has been in use as the medium through which NParks collects, shares, collates, and reports data, and enforces regulations pertaining to their parks and public spaces. With a ‘wireless eye’ on their city, they have elevated their position as a ‘city in a garden’ – their desired outcome when selecting <Link to='/lands-tracker'><strong>Lands-Tracker</strong></Link><span className="ics__p-™">™</span> as their management solution a decade ago.</p>
      </div>
      <div className='about__client'>
        <h3 className='ics__h2'>
          Land Information New Zealand (LINZ) <wbr />and Department of Conservation (DOC)
        </h3>
        <p className='ics__p'><a href="https://www.linz.govt.nz/" target="_blank" rel="noreferrer noopener"><strong>LINZ</strong></a> and <a href="https://www.doc.govt.nz/" target="_blank" rel="noreferrer noopener"><strong>DOC</strong></a> are responsible for Crown Land, property rights and geographic information, and the management of Heritage respectively. They collectively acquired the <Link to='/lands-tracker'><strong>Lands-Tracker</strong><span className='ics__p-™'>™</span></Link> system in 2012, as a replacement for existing legacy systems within both departments that were outdated and unable to meet new legislative requirements. The system is now the single source for management of the nation’s Crown Land, and is recognised for its capability to provide New Zealand with a ‘whole of government’ solution.</p>
      </div>
      <div className='about__client'>
        <h3 className='ics__h2'>
          Queensland Parks and Wildlife Service (QPWS)
        </h3>
        <p className='ics__p'><a href='https://parks.des.qld.gov.au/' target="_blank"><strong>QPWS</strong></a> – a division of the state’s <a href='https://www.des.qld.gov.au/' target="_blank" rel="noreferrer noopener"><strong>Department of Environment and Science</strong></a> – is responsible for protecting and managing marine and terrestrial parks and forests throughout the state, with a view to conserving ecosystems, preserving places of cultural heritage and minimising negative effects on the environment. In 2016, QPWS implemented the ICS system, <Link to='flame-tracker'><strong>Flame-Tracker</strong></Link><span className="ics__p-™">™</span>, to manage all aspects of planned and unplanned fire across Queensland’s protected areas and state forests. Subsequently, QPWS identified a need to better manage biodiversity in cases of infestation by plants or introduced species, and procured the ICS system, <Link to='/biodiversity-tracker'><strong>Biodiversity-Tracker</strong></Link><span className="ics__p-™">™</span>, modelled on Flame-Tracker™, and allowing the same ease of use, and transparency of up-to-the-moment information.</p>
      </div>
      <Img className="about__client-image" fluid={forestImage.childImageSharp.fluid} alt='' />
      <PacificMap />
    </section>
  )
}

export default React.forwardRef(SelectedClients)