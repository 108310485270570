import React, { useState, useEffect, useRef } from 'react'
import { window } from 'browser-monads'

import Layout from '../components/Layout'
import AboutHero from '../components/about/AboutHero'
import AboutNav from '../components/about/AboutNav'
import AboutHistory from '../components/about/AboutHistory'
import AboutServices from '../components/about/AboutServices'
import Values from '../components/about/Values'
import SelectedClients from '../components/about/SelectedClients'
import LeaderShip from '../components/about/LeaderShip'
import Team from '../components/about/Team'

const useOnScreen = (refs) => {
  const [isIntersecting, setIntersecting] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.map(entry => {
        if (entry.isIntersecting) {
          setIntersecting(entry.target.firstChild.id)
        }
      })
    }, { rootMargin: `-120px 0px -${(window.innerHeight - 120 - 1)}px` })

    refs.forEach(ref => {
      observer.observe(ref.current)
    })
  }, [])

  return [isIntersecting, setIntersecting];
}

export default function About() {

  const historyRef = useRef()
  const servicesRef = useRef()
  const valuesRef = useRef()
  const clientsRef = useRef()
  const leadershipRef = useRef()
  const teamRef = useRef()
  const refsArray = [historyRef, servicesRef, valuesRef, clientsRef, leadershipRef, teamRef]

  const [selected, setSelected] = useOnScreen(refsArray);

  return (
    <Layout title='About' url='/about' page='about'>
      <AboutHero />
      <AboutNav selected={selected} setSelected={setSelected} />
      <AboutHistory ref={historyRef} />
      <AboutServices ref={servicesRef} />
      <Values ref={valuesRef} />
      <SelectedClients ref={clientsRef} />
      <LeaderShip ref={leadershipRef} />
      <Team ref={teamRef} />
    </Layout>
  )
}