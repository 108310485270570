import React from 'react'

function Values(props, ref) {
  const values = [
    {
      id: 1,
      title: 'Sustainability',
      paragraph:
        'We harness technology to help sustain the finite resources of our planet, on which we are reliant for our very existence.',
    },
    {
      id: 2,
      title: 'Commitment',
      paragraph:
        'The way we commit to our clients is the way we commit to humankind: it is inherent in the systems we build and the work we do.',
    },
    {
      id: 3,
      title: 'Evolution',
      paragraph:
        'In our industry, it is as vital as oxygen to human life. Technology advances rapidly, and we are constantly learning, adapting, and demonstrating ingenuity.',
    },
    {
      id: 4,
      title: 'Integrity',
      paragraph:
        'Our systems promote transparency, accountability and a single source of truth; our business is hinged on these core principles.',
    },
    {
      id: 5,
      title: 'Longevity',
      paragraph:
        'We have been here since before most people had an email address, and we are not only keeping up, we are racing ahead.',
    },
    {
      id: 6,
      title: 'A Holistic Approach',
      paragraph:
        'Technology is no longer separate from us – it is woven into the fabric of the world in which we live; we take our obligation seriously to use it to the world’s advantage.',
    },
  ]
  return (
    <section className='about__values scroll-section' ref={ref}>
      <div className='anchor' id='values' aria-label='values'>anchor</div>
      <h2 className='ics__h1'>Our values</h2>
      <ul className='about__values__list'>
        {values.map((value) => (
          <li key={value.id} className='about__values__value'>
            <h3 className='ics__h2 --blue'>{value.title}</h3>
            <p className='ics__p'>{value.paragraph}</p>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default React.forwardRef(Values)