import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import arrowDown from '../../assets/images/icons/chevron_down_white.svg'

export default function AboutHero() {
  const data = useStaticQuery(graphql`
    query AboutHero {
      city: file(relativePath: { eq: "about/about-hero.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.city.childImageSharp.fluid,
    `linear-gradient(157.23deg, #FFFFFF 31.44%, rgba(255, 255, 255, 0) 73.01%)`
  ].reverse()

  return (
    <section className="about__hero">
      <BackgroundImage
        fluid={backgroundFluidImageStack}
        style={{ backgroundSize: 'cover', backgroundPosition: '0% 55%' }}
        alt='placeholder image of cityscape'
        className='about__content'>
        <h1 className='ics__h1 --white'>About us</h1>
        <p className='ics__p-intro'>ICS is an Australian software development company, specialising in enterprise software and advanced solutions for large organisations – particularly Government – involved in the management, measurement, planning and protection of natural values.</p>
        <img src={arrowDown} alt="Arrow pointing downwards" className="index__arrow" />
      </BackgroundImage>
    </section>
  )
}
