import React from 'react'
import { Link } from 'gatsby'

function AboutHistory(props, ref) {

  return (
    <section className='about__history scroll-section' id='about-history' ref={ref}>
      <div className='anchor' id="history" aria-label="history">anchor</div>
      <p className="ics__p-intro">Founded in the era of the internet’s infancy on an island housing ancient forests, ICS has evolved as a fusion of technological prowess with a matured social conscience.</p>
      <p className="ics__p">In the backyard of the ancient forests, and on the doorstep of sites layered with cultural and historical significance – otherwise known as Tasmania – the first iteration of the flagship ICS product, <Link to="/lands-tracker"><strong>Lands-Tracker</strong></Link><span className="ics__p-™">™</span>, was conceived and implemented.</p>
    </section>
  )
}

export default React.forwardRef(AboutHistory)