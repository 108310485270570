import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

function AboutServices(props, ref) {

  const { dataJson: { header: { trackers } } } = useStaticQuery(graphql`
  query AboutServices {
    dataJson {
      header {
        trackers {
          id
          name
          title
          image {
            publicURL
          }
          slug
        }
      }
    }
  }`)

  return (
    <section className='about__services scroll-section' id="about-services" ref={ref}>
      <div className='anchor' id="services" aria-label="services">anchor</div>
      <h2 className='ics__h1 '>Our services</h2>
      <div className='services_paragraphs'>
        <p className="ics__p-intro">The suite of ICS systems provides cutting-edge tools and contemporary approaches to management, with many thousands of development hours ensuring their robustness and longevity.</p>
        <p className="ics__p">As Software as a Service becomes today’s preferred method of delivery, the ICS systems bring with them the flexibility to adapt to the model, supported by an internet-length lifetime of knowledge and experience, incomparable with ‘SaaS’ competitors whose systems exist only in the cloud.</p>
        <p className="ics__p">As the company matures through its third decade, its promotion of sustainability and conservation proves to be as much a result of its implementations as a mobilising force behind its work, as the wellbeing of any populace depends on the wellbeing of the ecosystems that support and sustain it.</p>
      </div>
      <ul className="services-trackers">
        {trackers.map(tracker => (
          <li key={tracker.id}>
            <Link to={tracker.slug}>
              <img src={tracker.image.publicURL} alt='' />
              <span className="ics__h4">{tracker.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default React.forwardRef(AboutServices)