import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

function Team(props, ref) {
  const {
    dataJson: { team },
  } = useStaticQuery(graphql`
    query Teams {
      dataJson {
        team {
          id
          name
          title
          image {
            childImageSharp {
              fluid(maxWidth: 208, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className='about__team scroll-section' ref={ref}>
      <div className='anchor' id='team' aria-label='team'>anchor</div>
      <h2 className='ics__h1 '>Our team</h2>
      <div className='about__team-grid'>
        {team.map((member) => (
          <div className='about__team-member' key={member.id}>
            <Image fluid={member.image.childImageSharp.fluid} className='about__team-member-img' />
            <h3 className='ics__h2'>{member.name}</h3>
            <h4 className='ics__p'>— {member.title}</h4>
          </div>
        ))}
      </div>
    </section>
  )
}

export default React.forwardRef(Team)