import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const LeaderShip = (props, ref) => {
  const {
    dataJson: { leaders },
  } = useStaticQuery(graphql`
    query Leaders {
      dataJson {
        leaders {
          id
          name
          title
          paragraph
          image {
            childImageSharp {
              fixed(width: 208, height: 208) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className='about__leadership scroll-section' ref={ref}>
      <div
        className='anchor'
        id='leadership'
        aria-label='leadership'></div>
      <h2 className='ics__h1 '>Leadership</h2>
      {leaders.map((leader) => (
        <div className='about__leadership-card' key={leader.id}>
          <div className='about__leadership-info'>
            <h3 className='ics__h2'>{leader.name}</h3>
            <h4 className='ics__p'>— {leader.title}</h4>
          </div>
          <Image
            fixed={leader.image.childImageSharp.fixed}
            className='about__leadership-profile'
          />
          <p className='ics__p'>{leader.paragraph}</p>
        </div>
      ))}
    </section>
  )
}

export default React.forwardRef(LeaderShip)
