import React from 'react'

export default function AboutNav({ selected, setSelected }) {
  const quickLinks = [
    { id: 1, title: 'History', link: 'history' },
    { id: 2, title: 'Services', link: 'services' },
    { id: 3, title: 'Values', link: 'values' },
    { id: 4, title: 'Clients', link: 'clients' },
    { id: 5, title: 'Leadership', link: 'leadership' },
    { id: 6, title: 'Team', link: 'team' },
  ]

  const jumpTo = (ele) => {
    const element = document.getElementById(ele)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  const selectHandler = (title, link) => {
    jumpTo(link)
    setSelected(title.toLowerCase())
  }

  const style1 = {
    borderBottom: 'solid 3px var(--brand-primary)',
  }

  return (
    <nav className='about__nav' id="about-nav">
      <ul>
        {quickLinks.map(({ id, title, link }) => (
          <li key={id} id={`about-nav-${id}`} style={selected === title.toLowerCase() ? style1 : null}>
            <button
              className='ics__navlink'
              onClick={() => selectHandler(title, link)}>
              {title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  )
}
